package co.daneshvar.portfolio.architecture.view

import co.daneshvar.portfolio.architecture.domain.DomainState
import co.daneshvar.portfolio.architecture.mvvm.core.interfaces.ReactivePresenter
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.scan

class PortfolioPresenter : ReactivePresenter<DomainState, UIState> {

    override fun present(upstream: Flow<DomainState>): Flow<UIState> {
        return upstream
            .scan(UIState.initialState) { acc, domainState ->
                val direction =
                    if (domainState.currentMenu.ordinal < acc.menu.ordinal) -1
                    else if (domainState.currentMenu.ordinal > acc.menu.ordinal) 1
                    else acc.menuNavigationDirection

                return@scan UIState(
                    domainState.currentPage,
                    domainState.isLoadingContent,
                    domainState.content,
                    domainState.currentMenu,
                    direction
                )
            }
    }
}