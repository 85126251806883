package co.daneshvar.portfolio.architecture.view

import co.daneshvar.portfolio.architecture.domain.DomainAction
import co.daneshvar.portfolio.architecture.domain.DomainState
import co.daneshvar.portfolio.architecture.domain.PortfolioInteractor
import co.daneshvar.portfolio.architecture.mvvm.core.abstraction.ReactiveViewModel
import co.daneshvar.portfolio.architecture.sharedtypes.Page

class PortfolioViewModel : ReactiveViewModel<UIEvent, UIState, DomainAction, DomainState>(
    UIState.initialState,
    PortfolioInteractor(),
    PortfolioPresenter()
) {

    override fun mapEvent(event: UIEvent): DomainAction? {
        return when (event) {
            is UIEvent.ShowPage -> when (event.page) {
                Page.MAIN -> DomainAction.ViewMain
                Page.SKILL_SETS -> DomainAction.ViewSkillSets
            }
            UIEvent.LoadInfo -> DomainAction.LoadContent
            is UIEvent.SelectMenu -> DomainAction.SelectMenu(event.menu)
        }
    }
}