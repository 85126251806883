package co.daneshvar.portfolio.architecture.sharedtypes

import daneshvarco.composeapp.generated.resources.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.jetbrains.compose.resources.DrawableResource

@Serializable
enum class PLType {
    @SerialName("java")
    JAVA,

    @SerialName("swift")
    SWIFT,

    @SerialName("kotlin")
    KOTLIN,

    @SerialName("python")
    PYTHON,

    @SerialName("C++")
    C_PLUS_PLUS;


    val logo: DrawableResource
        get() = when (this) {
            JAVA -> Res.drawable.logo_java
            SWIFT -> Res.drawable.logo_swift
            KOTLIN -> Res.drawable.logo_kotlin
            PYTHON -> Res.drawable.logo_python
            C_PLUS_PLUS -> Res.drawable.logo_cpp
        }

    val title: String
        get() = when (this) {
            JAVA -> "Java"
            SWIFT -> "Swift"
            KOTLIN -> "Kotlin"
            PYTHON -> "Python"
            C_PLUS_PLUS -> "C/C++"
        }
}