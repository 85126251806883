package co.daneshvar.portfolio.style.views

import androidx.compose.animation.animateColorAsState
import androidx.compose.animation.core.animateDpAsState
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.hoverable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.daneshvar.portfolio.architecture.sharedtypes.Platform
import co.daneshvar.portfolio.architecture.sharedtypes.ProgrammingLanguage
import co.daneshvar.portfolio.style.FontFamilyCompositionLocal
import co.daneshvar.portfolio.web.openInNewTab
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun PortfolioButton(title: String, icon: ImageVector, onClick: () -> Unit) {
    val interactionSource = remember { MutableInteractionSource() }
    val isHovered by interactionSource.collectIsHoveredAsState()
    val elevation by animateDpAsState(if (isHovered) 4.dp else 0.dp)
    val color by animateColorAsState(
        if (isHovered) Color(0xFF2E2E2E) else Color(0xFF161616),
        animationSpec = tween(300)
    )
    Row(
        modifier = Modifier.pointerHoverIcon(PointerIcon.Hand)
            .shadow(elevation, shape = RoundedCornerShape(size = 7.0.dp)).clickable(onClick = onClick)
            .hoverable(interactionSource)
            .background(color, shape = RoundedCornerShape(size = 7.0.dp))
            .padding(12.dp),
        horizontalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        Icon(icon, contentDescription = null, tint = Color.White)
        RobotoText(title, fontSize = 21.sp)
    }
}

@Composable
fun RobotoText(
    text: String,
    fontSize: TextUnit = TextUnit.Unspecified,
    fontWeight: FontWeight = FontWeight.Normal,
    lineHeight: TextUnit = TextUnit.Unspecified,
    maxLines: Int = Int.MAX_VALUE,
    color: Color = Color.White,
    modifier: Modifier = Modifier
) {
    Text(
        text = text,
        modifier = modifier,
        color = color,
        fontSize = fontSize,
        fontWeight = fontWeight,
        lineHeight = lineHeight,
        maxLines = maxLines,
        fontFamily = FontFamilyCompositionLocal.current
    )
}

@Composable
fun ContentBox(
    title: String,
    subTitle: String? = null,
    icon: Painter,
    tint: Color = Color(0xFF463333),
    modifier: Modifier = Modifier,
    content: @Composable ColumnScope.() -> Unit
) {
    Column(
        verticalArrangement = Arrangement.spacedBy(4.dp),
        modifier = modifier.background(
            Brush.verticalGradient(listOf(tint, Color.Black.copy(alpha = 0.8f))), shape = RoundedCornerShape(12.dp)
        ).padding(8.dp),
    ) {
        Row(
            horizontalArrangement = Arrangement.spacedBy(8.dp),
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.height(40.dp)
        ) {
            Icon(icon, contentDescription = null, tint = Color.White, modifier = Modifier.fillMaxHeight())

            Column {
                RobotoText(
                    text = title,
                    color = Color.White,
                    fontSize = 16.sp,
                    fontWeight = FontWeight.Medium,
                    lineHeight = 10.sp
                )
                RobotoText(
                    text = subTitle ?: "",
                    color = Color(0xFFC7C7C7),
                    fontSize = 11.sp,
                    fontWeight = FontWeight.Medium,
                    lineHeight = 5.sp
                )
            }

        }

        Divider(color = Color.White.copy(0.3f), thickness = 0.5.dp)

        content()
    }
}

@Composable
fun ChipView(text: String, textColor: Color = Color.White, color: Color = Color.Gray) {
    RobotoText(
        text,
        color = textColor,
        modifier = Modifier
            .background(color, shape = RoundedCornerShape(8.dp))
            .padding(8.dp, 2.dp)
    )
}

@Composable
fun IconWithTitle(icon: DrawableResource, text: String, tint: Color = Color(0xFFECECEC)) {
    Column(horizontalAlignment = Alignment.CenterHorizontally, verticalArrangement = Arrangement.spacedBy(8.dp)) {
        Image(painterResource(icon), contentDescription = null, modifier = Modifier.height(50.dp))

        RobotoText(text, color = tint, fontSize = 14.sp)
    }
}

@Composable
fun LanguageItem(pl: ProgrammingLanguage) {
    Row(horizontalArrangement = Arrangement.spacedBy(12.dp)) {
        Image(
            painterResource(pl.key.logo),
            contentDescription = null,
            modifier = Modifier.width(36.dp),
            contentScale = ContentScale.Fit
        )

        Column {
            // ** Language title
            RobotoText(
                pl.key.title,
                fontSize = 20.sp,
                fontWeight = FontWeight.Medium,
                lineHeight = 10.sp,
                color = Color.White
            )

            // ** Subtitle
            RobotoText(
                pl.subtitle,
                fontSize = 12.sp,
                fontWeight = FontWeight.Medium,
                lineHeight = 10.sp,
                color = Color.White
            )

            // ** Note
            RobotoText(
                pl.note,
                fontSize = 16.sp,
                fontWeight = FontWeight.Normal,
                color = Color.White
            )
        }
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun PlatformItem(platform: Platform, tint: Color, specificContent: @Composable (() -> Unit)? = null) {
    Column(verticalArrangement = Arrangement.spacedBy(8.dp), modifier = Modifier.padding(8.dp)) {
        // ** Background
        RobotoText(
            "Background",
            fontWeight = FontWeight.Bold,
            lineHeight = 10.sp,
            fontSize = 22.sp,
            color = Color.White
        )

        // ** Note
        RobotoText(
            platform.description ?: "",
            fontWeight = FontWeight.Normal,
            fontSize = 16.sp,
            color = Color.White
        )

        // ** Specific Content
        specificContent?.let { it() }

        // ** Frameworks
        RobotoText(
            "Frameworks",
            fontWeight = FontWeight.Bold,
            lineHeight = 10.sp,
            fontSize = 22.sp,
            color = Color.White,
            modifier = Modifier.padding(top = 8.dp)
        )

        // ** Framework items
        FlowRow(horizontalArrangement = Arrangement.spacedBy(8.dp), verticalArrangement = Arrangement.spacedBy(8.dp)) {
            for (fw in platform.frameworks) {
                ChipView(fw.name, color = tint)
            }
        }
    }
}

@Composable
fun ContactItem(icon: DrawableResource, title: String, onclick: () -> Unit) {
    val interactionSource = remember { MutableInteractionSource() }
    val isHovered by interactionSource.collectIsHoveredAsState()
    val elevation by animateDpAsState(if (isHovered) 4.dp else 0.dp)
    val color by animateColorAsState(
        if (isHovered) Color(0xFF2E2E2E) else Color(0xFF1C1C1C),
        animationSpec = tween(300)
    )

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(12.dp),
        modifier = Modifier.pointerHoverIcon(PointerIcon.Hand)
            .shadow(elevation, shape = RoundedCornerShape(size = 7.0.dp))
            .clickable(onClick = onclick)
            .hoverable(interactionSource)
            .background(color, shape = RoundedCornerShape(size = 7.0.dp))
            .padding(12.dp),
    ) {
        Image(painterResource(icon), contentDescription = null, modifier = Modifier.width(30.dp))

        RobotoText(title, fontSize = 14.sp, color = Color.White, fontWeight = FontWeight.Medium)
    }
}

@Composable
fun Link(
    text: String,
    href: String,
    fontSize: TextUnit,
    fontWeight: FontWeight,
    color: Color = Color(0xFF6779DA),
    modifier: Modifier = Modifier
) {
    val interactionSource = remember { MutableInteractionSource() }
    val isHovered by interactionSource.collectIsHoveredAsState()
    val color by animateColorAsState(
        if (isHovered) color.copy(0.5f) else color,
        animationSpec = tween(300)
    )

    RobotoText(
        text,
        fontSize = fontSize,
        fontWeight = fontWeight,
        color = color,
        modifier = modifier.pointerHoverIcon(PointerIcon.Hand)
            .hoverable(interactionSource)
            .clickable(onClick = {
                openInNewTab(href)
            })
    )
}