package co.daneshvar.portfolio.style

import androidx.compose.runtime.ProvidableCompositionLocal
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.platform.Font
import daneshvarco.composeapp.generated.resources.Res
import org.jetbrains.compose.resources.ExperimentalResourceApi

interface PortfolioTheme {
    companion object {
        val mainColor = Color(0xFF1C1C1C)
    }
}

@OptIn(ExperimentalResourceApi::class)
suspend fun loadFontFamily(): FontFamily {
    val fontData = Res.readBytes("files/RobotoCondensed-VariableFont_wght.ttf")
    val fontFamily = FontFamily(
        Font("RobotoCondensed-VariableFont-Normal", fontData, FontWeight.Normal, FontStyle.Normal),
        Font("RobotoCondensed-VariableFont-Bold", fontData, FontWeight.Bold, FontStyle.Normal),
        Font("RobotoCondensed-VariableFont-Medium", fontData, FontWeight.Medium, FontStyle.Normal),
        Font("RobotoCondensed-VariableFont-SemiBold", fontData, FontWeight.SemiBold, FontStyle.Normal),
        Font("RobotoCondensed-VariableFont-Light", fontData, FontWeight.Light, FontStyle.Normal),
    )

    FontFamilyCompositionLocal = staticCompositionLocalOf { fontFamily }

    return fontFamily
}

lateinit var FontFamilyCompositionLocal: ProvidableCompositionLocal<FontFamily>