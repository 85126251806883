package co.daneshvar.portfolio.architecture.domain

import co.daneshvar.portfolio.architecture.sharedtypes.Content
import co.daneshvar.portfolio.architecture.sharedtypes.MenuType
import co.daneshvar.portfolio.architecture.sharedtypes.Page

data class DomainState(
    var currentPage: Page,
    var content: Content?,
    var isLoadingContent: Boolean,
    var currentMenu: MenuType
) {
    companion object {
        val initialState = DomainState(Page.MAIN, null, false, MenuType.PROGRAMMING_LANGUAGES)
    }
}
