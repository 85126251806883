package co.daneshvar.portfolio.architecture.sharedtypes

import kotlinx.serialization.Serializable

@Serializable
data class Content(
    val title: String,
    val subTitle: String,
    val bio: String,
    val signatureNote: String,
    val cvUrl: String,
    val contactInfo: ContactInfo,
    val skills: Skills
)

@Serializable
data class Skills(
    val programmingLanguages: List<ProgrammingLanguage>,
    val applePlatform: Platform,
    val androidPlatform: Platform,
)

@Serializable
data class ProgrammingLanguage(
    val key: PLType,
    val subtitle: String,
    val note: String
)

@Serializable
data class Platform(
    val name: String,
    val subtitle: String,
    val description: String? = null,
    val frameworks: List<Framework>
)

@Serializable
data class Framework(
    val name: String,
    val experience: Int
)

@Serializable
data class ContactInfo(
    val github: String,
    val linkedin: String
)