@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package daneshvarco.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val siteTitle: StringResource by 
      lazy { init_siteTitle() }
}

internal val Res.string.siteTitle: StringResource
  get() = CommonMainString0.siteTitle

private fun init_siteTitle(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:siteTitle", "siteTitle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/values/strings.commonMain.cvr", 10,
    37),
    )
)
