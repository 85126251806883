package co.daneshvar.portfolio

import androidx.compose.animation.*
import androidx.compose.animation.core.tween
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.width
import androidx.compose.material.LinearProgressIndicator
import androidx.compose.material.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.unit.dp
import co.daneshvar.portfolio.architecture.sharedtypes.Page
import co.daneshvar.portfolio.architecture.view.PortfolioViewModel
import co.daneshvar.portfolio.architecture.view.UIEvent
import co.daneshvar.portfolio.style.PortfolioTheme
import co.daneshvar.portfolio.views.MainPage
import co.daneshvar.portfolio.views.SkillsPage
import co.daneshvar.portfolio.web.openInNewTab
import kotlinx.coroutines.delay
import org.koin.compose.viewmodel.koinViewModel

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun App(viewModel: PortfolioViewModel = koinViewModel()) {

    val uiState by viewModel.uiState.collectAsState()

    LaunchedEffect(Unit) {
        delay(1)
        viewModel.sendEvent(UIEvent.LoadInfo)
    }

    MaterialTheme {

        // ** Dynamic background color
        val themeColor by animateColorAsState(
            if (uiState.page == Page.MAIN) PortfolioTheme.mainColor else uiState.menu.color,
            tween(durationMillis = 800)
        )
        val dynamicBackground = Brush.radialGradient(
            listOf(themeColor, PortfolioTheme.mainColor),
            Offset(LocalWindowInfo.current.containerSize.width.toFloat() / 2.0f, 0.0f)
        )

        Box(
            Modifier.fillMaxSize().background(dynamicBackground), contentAlignment = Alignment.Center
        ) {
            // ** If it is needed to load the content
            if (uiState.isLoading) {
                LinearProgressIndicator(modifier = Modifier.width(200.dp), color = Color.Gray)
            }

            uiState.content?.let { content ->
                Box(
                    contentAlignment = Alignment.Center,
                    modifier = Modifier.fillMaxSize().animateContentSize()
                ) {

                    AnimatedPage(Page.MAIN) {
                        MainPage(content, skillSetCallback = {
                            viewModel.sendEvent(UIEvent.ShowPage(Page.SKILL_SETS))
                        }, downloadCvCallback = {
                            uiState.content?.let {
                                openInNewTab(it.cvUrl)
                            }
                        })
                    }

                    AnimatedPage(Page.SKILL_SETS) {
                        SkillsPage()
                    }
                }
            }

        }
    }
}

@Composable
fun AnimatedPage(page: Page, content: @Composable () -> Unit) {
    val uiState by koinViewModel<PortfolioViewModel>().uiState.collectAsState()

    val isVisible = uiState.page == page
    val direction = if (page == Page.MAIN) -1 else 1
    AnimatedVisibility(
        isVisible,
        enter = slideInHorizontally(initialOffsetX = { it * direction }) + fadeIn(),
        exit = slideOutHorizontally(targetOffsetX = { it * direction }) + fadeOut()
    ) {
        content()
    }
}