package co.daneshvar.portfolio.architecture.domain

import co.daneshvar.portfolio.architecture.mvvm.core.interfaces.ReactiveInteractor
import co.daneshvar.portfolio.architecture.sharedtypes.Content
import co.daneshvar.portfolio.architecture.sharedtypes.MenuType
import co.daneshvar.portfolio.architecture.sharedtypes.Page
import daneshvarco.composeapp.generated.resources.Res
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.*
import kotlinx.serialization.json.Json
import org.jetbrains.compose.resources.ExperimentalResourceApi

class PortfolioInteractor : ReactiveInteractor<DomainAction, DomainState> {

    override fun invoke(upstream: Flow<DomainAction>): Flow<DomainState> {
        return merge(upstream, loadContentStream(upstream))
            .scan(DomainState.initialState) { currentState, action ->
                return@scan when (action) {
                    DomainAction.ViewMain -> updatePage(currentState, Page.MAIN)
                    DomainAction.ViewSkillSets -> updatePage(currentState, Page.SKILL_SETS)
                    DomainAction.LoadContent -> currentState.apply {
                        this.isLoadingContent = true
                    }

                    is DomainAction.LoadContentSuccess -> wrapContent(currentState, action.content)
                    is DomainAction.SelectMenu -> selectMenu(currentState, action.menu)
                }
            }
    }

    private fun updatePage(domainState: DomainState, page: Page) = domainState.apply {
        this.currentPage = page
    }

    @OptIn(ExperimentalResourceApi::class, ExperimentalCoroutinesApi::class)
    private fun loadContentStream(upStream: Flow<DomainAction>): Flow<DomainAction> {
        return upStream
            .flatMapMerge {
                flow {
                    // ** Ignore other actions
                    if (it != DomainAction.LoadContent) {
                        emit(it)
                        return@flow
                    }

                    val contentString = Res.readBytes("files/info.json").decodeToString()
                    val json = Json { ignoreUnknownKeys = true }
                    val content = json.decodeFromString<Content>(contentString)

                    emit(DomainAction.LoadContentSuccess(content))
                }
            }
    }

    private fun wrapContent(state: DomainState, content: Content): DomainState {
        return state.apply {
            this.content = content
            this.isLoadingContent = false
        }
    }

    private fun selectMenu(state: DomainState, menu: MenuType): DomainState {
        return state.apply {
            this.currentMenu = menu
        }
    }
}