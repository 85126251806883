package co.daneshvar.portfolio.architecture.mvvm.core.interfaces

import kotlinx.coroutines.flow.Flow

public fun <E, S> Flow<E>.interact(interactor: ReactiveInteractor<E, S>): Flow<S> {
    return interactor(this)
}

public fun <DS, US> Flow<DS>.present(presenter: ReactivePresenter<DS, US>): Flow<US> {
    return presenter.present(this)
}
