package co.daneshvar.portfolio.views

import androidx.compose.animation.*
import androidx.compose.animation.core.Spring
import androidx.compose.animation.core.spring
import androidx.compose.animation.core.tween
import androidx.compose.foundation.*
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Icon
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.daneshvar.portfolio.architecture.sharedtypes.MenuType
import co.daneshvar.portfolio.architecture.sharedtypes.Page
import co.daneshvar.portfolio.architecture.view.PortfolioViewModel
import co.daneshvar.portfolio.architecture.view.UIEvent
import co.daneshvar.portfolio.architecture.view.isMenuSelected
import co.daneshvar.portfolio.style.views.*
import daneshvarco.composeapp.generated.resources.*
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.viewmodel.koinViewModel

@Composable
fun SkillsPage() {

    val viewModel: PortfolioViewModel = koinViewModel()
    val uiState by viewModel.uiState.collectAsState()

    Column {
        NavigationBar {
            viewModel.sendEvent(UIEvent.ShowPage(Page.MAIN))
        }

        Row(
            horizontalArrangement = Arrangement.spacedBy(24.dp),
            verticalAlignment = Alignment.Top,
            modifier = Modifier.height(600.dp)
        ) {
            Menu()

            Box {
                AnimatedContentBox(MenuType.PROGRAMMING_LANGUAGES) {

                    val scrollState = rememberScrollState(0)
                    Column(
                        verticalArrangement = Arrangement.spacedBy(16.dp),
                        modifier = Modifier.verticalScroll(scrollState)
                    ) {
                        uiState.content?.let { content ->
                            for (pl in content.skills.programmingLanguages) {
                                LanguageItem(pl)
                            }
                        }
                    }
                }

                AnimatedContentBox(MenuType.APPLE_PLATFORM) {
                    val scrollState = rememberScrollState(0)
                    Column(
                        verticalArrangement = Arrangement.spacedBy(16.dp),
                        modifier = Modifier.verticalScroll(scrollState)
                    ) {
                        uiState.content?.let { content ->
                            PlatformItem(content.skills.applePlatform, tint = MenuType.APPLE_PLATFORM.color) {

                                Row(
                                    horizontalArrangement = Arrangement.SpaceEvenly,
                                    modifier = Modifier.fillMaxWidth().padding(top = 8.dp)
                                ) {
                                    IconWithTitle(Res.drawable.icon_ios_ipados, "iOS/iPadOS")
                                    IconWithTitle(Res.drawable.icon_watchos, "watchOS")
                                    IconWithTitle(Res.drawable.icon_visionos, "visionOS")
                                    IconWithTitle(Res.drawable.icon_macos, "macOS")
                                }
                            }
                        }
                    }
                }

                AnimatedContentBox(MenuType.ANDROID_PLATFORM) {
                    val scrollState = rememberScrollState(0)
                    Column(
                        verticalArrangement = Arrangement.spacedBy(16.dp),
                        modifier = Modifier.verticalScroll(scrollState)
                    ) {
                        uiState.content?.let { content ->
                            PlatformItem(content.skills.androidPlatform, tint = MenuType.ANDROID_PLATFORM.color)
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun Menu(viewModel: PortfolioViewModel = koinViewModel()) {
    Column(
        modifier = Modifier.width(360.dp)
            .background(Color(0xFF262626), shape = RoundedCornerShape(12.dp))
            .padding(vertical = 22.dp)
    ) {
        val uiState by viewModel.uiState.collectAsState()

        MenuItem("Programing Languages", uiState.isMenuSelected(MenuType.PROGRAMMING_LANGUAGES)) {
            viewModel.sendEvent(UIEvent.SelectMenu(MenuType.PROGRAMMING_LANGUAGES))
        }
        MenuItem("Apple Platform", uiState.isMenuSelected(MenuType.APPLE_PLATFORM)) {
            viewModel.sendEvent(UIEvent.SelectMenu(MenuType.APPLE_PLATFORM))
        }
        MenuItem("Android Platform", uiState.isMenuSelected(MenuType.ANDROID_PLATFORM)) {
            viewModel.sendEvent(UIEvent.SelectMenu(MenuType.ANDROID_PLATFORM))
        }
    }
}

@Composable
private fun MenuItem(title: String, selected: Boolean, callback: () -> Unit) {
    val interactionSource = remember { MutableInteractionSource() }
    val isHovered by interactionSource.collectIsHoveredAsState()
    val color by animateColorAsState(
        if (isHovered) Color(0xFF2E2E2E) else Color(0xFF262626),
        animationSpec = tween(300)
    )
    val backgroundBrush = if (selected) Brush.horizontalGradient(
        listOf(
            Color(0xFF262626),
            Color(0XFF1C1C1C)
        )
    ) else Brush.horizontalGradient(listOf(color, color))

    Row(
        modifier = Modifier.pointerHoverIcon(PointerIcon.Hand)
            .hoverable(interactionSource)
            .clickable(onClick = callback)
            .background(backgroundBrush)
            .padding(12.dp),
        horizontalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        RobotoText(title, fontSize = 21.sp)

        Spacer(Modifier.weight(1f))
        Icon(painterResource(Res.drawable.chevron_right), contentDescription = null, tint = Color.White)
    }
}

@Composable
private fun AnimatedContentBox(menuType: MenuType, content: @Composable ColumnScope.() -> Unit) {

    val uiState by koinViewModel<PortfolioViewModel>().uiState.collectAsState()

    AnimatedVisibility(
        uiState.isMenuSelected(menuType),
        enter = slideInVertically(
            initialOffsetY = { it * uiState.menuNavigationDirection },
            animationSpec = spring(Spring.DampingRatioLowBouncy, stiffness = Spring.StiffnessLow)
        ) + fadeIn(),
        exit = slideOutVertically(
            targetOffsetY = { -it * uiState.menuNavigationDirection },
            animationSpec = spring(Spring.DampingRatioLowBouncy, stiffness = Spring.StiffnessLow)
        ) + fadeOut(),
    ) {
        ContentBox(
            menuType.title,
            menuType.subtitle,
            painterResource(menuType.icon),
            tint = menuType.color,
            modifier = Modifier.width(500.dp), content
        )
    }
}

@Composable
fun NavigationBar(onBackClicked: () -> Unit) {
    Row(
        horizontalArrangement = Arrangement.spacedBy(24.dp),
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.height(100.dp)
    ) {

        PortfolioButton("Back", Icons.AutoMirrored.Default.ArrowBack) {
            onBackClicked()
        }

        RobotoText(
            Page.SKILL_SETS.title,
            fontSize = 31.sp,
            fontWeight = FontWeight.Medium,
            lineHeight = 10.sp
        )
    }

}