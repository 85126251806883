package co.daneshvar.portfolio

import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import co.daneshvar.portfolio.di.diModule
import co.daneshvar.portfolio.style.FontFamilyCompositionLocal
import co.daneshvar.portfolio.style.loadFontFamily
import daneshvarco.composeapp.generated.resources.Res
import daneshvarco.composeapp.generated.resources.siteTitle
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.getString
import org.jetbrains.skiko.wasm.onWasmReady
import org.koin.compose.KoinApplication
import org.koin.dsl.koinApplication

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    MainScope().launch {
        val fontFamily = loadFontFamily()
        val siteName = getString(Res.string.siteTitle)
        onWasmReady {
            CanvasBasedWindow(siteName, canvasElementId = "ComposeTarget") {
                KoinApplication(::diConfigurations) {
                    CompositionLocalProvider(FontFamilyCompositionLocal provides fontFamily) {
                        App()
                    }
                }
            }
        }
    }
}

private fun diConfigurations() = koinApplication {
    modules(diModule)
}