@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package daneshvarco.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val chevron_right: DrawableResource by 
      lazy { init_chevron_right() }

  public val code: DrawableResource by 
      lazy { init_code() }

  public val icon_email: DrawableResource by 
      lazy { init_icon_email() }

  public val icon_ios_ipados: DrawableResource by 
      lazy { init_icon_ios_ipados() }

  public val icon_macos: DrawableResource by 
      lazy { init_icon_macos() }

  public val icon_visionos: DrawableResource by 
      lazy { init_icon_visionos() }

  public val icon_watchos: DrawableResource by 
      lazy { init_icon_watchos() }

  public val logo_android: DrawableResource by 
      lazy { init_logo_android() }

  public val logo_apple: DrawableResource by 
      lazy { init_logo_apple() }

  public val logo_cpp: DrawableResource by 
      lazy { init_logo_cpp() }

  public val logo_github: DrawableResource by 
      lazy { init_logo_github() }

  public val logo_java: DrawableResource by 
      lazy { init_logo_java() }

  public val logo_jpcompose: DrawableResource by 
      lazy { init_logo_jpcompose() }

  public val logo_kotlin: DrawableResource by 
      lazy { init_logo_kotlin() }

  public val logo_linkedin: DrawableResource by 
      lazy { init_logo_linkedin() }

  public val logo_python: DrawableResource by 
      lazy { init_logo_python() }

  public val logo_swift: DrawableResource by 
      lazy { init_logo_swift() }

  public val profile_image: DrawableResource by 
      lazy { init_profile_image() }
}

internal val Res.drawable.chevron_right: DrawableResource
  get() = CommonMainDrawable0.chevron_right

private fun init_chevron_right(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:chevron_right",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/chevron-right.svg", -1, -1),
    )
)

internal val Res.drawable.code: DrawableResource
  get() = CommonMainDrawable0.code

private fun init_code(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:code",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/code.svg", -1, -1),
    )
)

internal val Res.drawable.icon_email: DrawableResource
  get() = CommonMainDrawable0.icon_email

private fun init_icon_email(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/icon-email.svg", -1, -1),
    )
)

internal val Res.drawable.icon_ios_ipados: DrawableResource
  get() = CommonMainDrawable0.icon_ios_ipados

private fun init_icon_ios_ipados(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_ios_ipados",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/icon-ios-ipados.svg", -1, -1),
    )
)

internal val Res.drawable.icon_macos: DrawableResource
  get() = CommonMainDrawable0.icon_macos

private fun init_icon_macos(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_macos",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/icon-macos.svg", -1, -1),
    )
)

internal val Res.drawable.icon_visionos: DrawableResource
  get() = CommonMainDrawable0.icon_visionos

private fun init_icon_visionos(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_visionos",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/icon-visionos.svg", -1, -1),
    )
)

internal val Res.drawable.icon_watchos: DrawableResource
  get() = CommonMainDrawable0.icon_watchos

private fun init_icon_watchos(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_watchos",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/icon-watchos.svg", -1, -1),
    )
)

internal val Res.drawable.logo_android: DrawableResource
  get() = CommonMainDrawable0.logo_android

private fun init_logo_android(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_android",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/logo-android.svg", -1, -1),
    )
)

internal val Res.drawable.logo_apple: DrawableResource
  get() = CommonMainDrawable0.logo_apple

private fun init_logo_apple(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_apple",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/logo-apple.svg", -1, -1),
    )
)

internal val Res.drawable.logo_cpp: DrawableResource
  get() = CommonMainDrawable0.logo_cpp

private fun init_logo_cpp(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_cpp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/logo-cpp.svg", -1, -1),
    )
)

internal val Res.drawable.logo_github: DrawableResource
  get() = CommonMainDrawable0.logo_github

private fun init_logo_github(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_github",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/logo-github.svg", -1, -1),
    )
)

internal val Res.drawable.logo_java: DrawableResource
  get() = CommonMainDrawable0.logo_java

private fun init_logo_java(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_java",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/logo-java.svg", -1, -1),
    )
)

internal val Res.drawable.logo_jpcompose: DrawableResource
  get() = CommonMainDrawable0.logo_jpcompose

private fun init_logo_jpcompose(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_jpcompose",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/logo-jpcompose.svg", -1, -1),
    )
)

internal val Res.drawable.logo_kotlin: DrawableResource
  get() = CommonMainDrawable0.logo_kotlin

private fun init_logo_kotlin(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_kotlin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/logo-kotlin.svg", -1, -1),
    )
)

internal val Res.drawable.logo_linkedin: DrawableResource
  get() = CommonMainDrawable0.logo_linkedin

private fun init_logo_linkedin(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_linkedin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/logo-linkedin.svg", -1, -1),
    )
)

internal val Res.drawable.logo_python: DrawableResource
  get() = CommonMainDrawable0.logo_python

private fun init_logo_python(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_python",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/logo-python.svg", -1, -1),
    )
)

internal val Res.drawable.logo_swift: DrawableResource
  get() = CommonMainDrawable0.logo_swift

private fun init_logo_swift(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_swift",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/logo-swift.svg", -1, -1),
    )
)

internal val Res.drawable.profile_image: DrawableResource
  get() = CommonMainDrawable0.profile_image

private fun init_profile_image(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:profile_image",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/daneshvarco.composeapp.generated.resources/drawable/profile_image.jpg", -1, -1),
    )
)
