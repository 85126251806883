package co.daneshvar.portfolio.architecture.sharedtypes

import androidx.compose.ui.graphics.Color
import daneshvarco.composeapp.generated.resources.Res
import daneshvarco.composeapp.generated.resources.code
import daneshvarco.composeapp.generated.resources.logo_android
import daneshvarco.composeapp.generated.resources.logo_apple
import org.jetbrains.compose.resources.DrawableResource

enum class MenuType {
    PROGRAMMING_LANGUAGES,
    APPLE_PLATFORM,
    ANDROID_PLATFORM,
    BACK_END;


    val color: Color
        get() = when (this) {
            PROGRAMMING_LANGUAGES -> Color(0xFF463333)
            APPLE_PLATFORM -> Color(0xFF333646)
            ANDROID_PLATFORM -> Color(0xFF384633)
            BACK_END -> Color(0xFF334640)
        }

    val title: String
        get() = when (this) {
            PROGRAMMING_LANGUAGES -> "Programming Language"
            APPLE_PLATFORM -> "Apple Platform"
            ANDROID_PLATFORM -> "Android Platform"
            BACK_END -> "Backend"
        }

    val subtitle: String
        get() = when (this) {
            PROGRAMMING_LANGUAGES -> "The languages that I worked with"
            APPLE_PLATFORM -> "Since 2013"
            ANDROID_PLATFORM -> "Since 2011"
            BACK_END -> "Since 2018"
        }

    val icon: DrawableResource
        get() = when (this) {
            PROGRAMMING_LANGUAGES -> Res.drawable.code
            APPLE_PLATFORM -> Res.drawable.logo_apple
            ANDROID_PLATFORM -> Res.drawable.logo_android
            BACK_END -> TODO()
        }
}