package co.daneshvar.portfolio.architecture.mvvm.core.abstraction

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import co.daneshvar.portfolio.architecture.mvvm.core.interfaces.ReactiveInteractor
import co.daneshvar.portfolio.architecture.mvvm.core.interfaces.ReactivePresenter
import co.daneshvar.portfolio.architecture.mvvm.core.interfaces.interact
import co.daneshvar.portfolio.architecture.mvvm.core.interfaces.present
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch

abstract class ReactiveViewModel<UIEvent, UIState, DomainAction, DomainState>(
    initialState: UIState,
    private val interactor: ReactiveInteractor<DomainAction, DomainState>,
    private val presenter: ReactivePresenter<DomainState, UIState>
) : ViewModel() {
    private var _uiState: MutableStateFlow<UIState> = MutableStateFlow(initialState)
    val uiState = _uiState.asStateFlow()

    private val eventStream: MutableSharedFlow<UIEvent> = MutableSharedFlow()

    init {
        viewModelScope.launch {
            eventStream
                .mapNotNull { mapEvent(it) }
                .interact(interactor)
                .present(presenter)
                .flowOn(Dispatchers.Default)
                .collect {
                    _uiState.value = it
                }
        }
    }

    abstract fun mapEvent(event: UIEvent): DomainAction?

    fun sendEvent(event: UIEvent) = viewModelScope.launch {
        eventStream.emit(event)
    }
}