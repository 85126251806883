package co.daneshvar.portfolio.architecture.sharedtypes

enum class Page {
    MAIN, SKILL_SETS;

    val title: String
        get() = when (this) {
            MAIN -> "Main"
            SKILL_SETS -> "Skill Sets"
        }
}