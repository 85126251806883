package co.daneshvar.portfolio.architecture.domain

import co.daneshvar.portfolio.architecture.sharedtypes.Content
import co.daneshvar.portfolio.architecture.sharedtypes.MenuType

sealed interface DomainAction {
    data object LoadContent : DomainAction
    data object ViewMain : DomainAction
    data object ViewSkillSets : DomainAction
    data class LoadContentSuccess(val content: Content) : DomainAction
    data class SelectMenu(val menu: MenuType) : DomainAction
}