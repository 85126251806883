package co.daneshvar.portfolio.architecture.view

import co.daneshvar.portfolio.architecture.sharedtypes.Content
import co.daneshvar.portfolio.architecture.sharedtypes.MenuType
import co.daneshvar.portfolio.architecture.sharedtypes.Page

data class UIState(
    val page: Page,
    val isLoading: Boolean,
    val content: Content?,
    val menu: MenuType,
    val menuNavigationDirection: Int
) {

    companion object {
        val initialState = UIState(Page.MAIN, false, null, MenuType.PROGRAMMING_LANGUAGES, 1)
    }
}

fun UIState.isMenuSelected(menu: MenuType) = this.menu == menu