package co.daneshvar.portfolio.views

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Divider
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Menu
import androidx.compose.material.icons.filled.Person
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.daneshvar.portfolio.architecture.sharedtypes.Content
import co.daneshvar.portfolio.style.views.ContactItem
import co.daneshvar.portfolio.style.views.Link
import co.daneshvar.portfolio.style.views.PortfolioButton
import co.daneshvar.portfolio.style.views.RobotoText
import co.daneshvar.portfolio.web.openInNewTab
import daneshvarco.composeapp.generated.resources.*
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.resources.painterResource

@Composable
fun MainPage(content: Content, skillSetCallback: () -> Unit, downloadCvCallback: () -> Unit) {

    Column(horizontalAlignment = Alignment.CenterHorizontally) {
        Row(
            horizontalArrangement = Arrangement.spacedBy(24.dp),
            verticalAlignment = Alignment.Top,
            modifier = Modifier.height(400.dp)
        ) {
            ProfileImage()

            Column(horizontalAlignment = Alignment.Start, modifier = Modifier.width(600.dp)) {
                RobotoText(content.title, fontWeight = FontWeight.Bold, fontSize = 43.0.sp)
                RobotoText(
                    content.subTitle,
                    fontSize = 19.0.sp,
                    color = Color(0xFFC8C8C8),
                    modifier = Modifier.padding(top = 12.dp)
                )

                RobotoText(
                    content.bio,
                    fontSize = 21.0.sp,
                    modifier = Modifier.padding(top = 64.dp),
                    lineHeight = 30.sp
                )
                RobotoText(
                    content.signatureNote,
                    fontSize = 21.0.sp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(top = 16.dp)
                )

                Spacer(Modifier.weight(1f, true))

                Row(horizontalArrangement = Arrangement.spacedBy(12.dp)) {
                    PortfolioButton("My Skill sets", Icons.Filled.Menu) {
                        skillSetCallback()
                    }

                    PortfolioButton("Download my resume", Icons.Filled.Person) {
                        downloadCvCallback()
                    }
                }
            }
        }

        Divider(modifier = Modifier.width(900.dp).padding(top = 32.dp, bottom = 16.dp), color = Color.Gray.copy(0.3f))

        ContactMe(content)

        Divider(modifier = Modifier.width(900.dp).padding(top = 32.dp, bottom = 16.dp), color = Color.Gray.copy(0.3f))

        PoweredBy()
        Signature()
    }
}

@Composable
private fun ContactMe(content: Content) {
    RobotoText("Contact with me", fontWeight = FontWeight.Normal, fontSize = 18.sp)
    Row(horizontalArrangement = Arrangement.SpaceEvenly, modifier = Modifier.width(400.dp).padding(top = 8.dp)) {
        ContactItem(Res.drawable.logo_github, "GitHub") {
            openInNewTab(content.contactInfo.github)
        }

        ContactItem(Res.drawable.logo_linkedin, "LinkedIn") {
            openInNewTab(content.contactInfo.linkedin)
        }

        ContactItem(Res.drawable.icon_email, "Email") {
            openInNewTab("mailto:ahmad@daneshvar.co")
        }
    }
}

@Composable
private fun PoweredBy() {
    Row(verticalAlignment = Alignment.CenterVertically) {
        Image(painterResource(Res.drawable.logo_jpcompose), contentDescription = "JPCompose Logo")

        RobotoText(
            "Powered by Compose Multiplatform. ",
            fontSize = 14.sp,
            fontWeight = FontWeight.Medium,
            modifier = Modifier.padding(start = 8.dp)
        )

        Link(
            "Learn more...",
            href = "https://www.jetbrains.com/lp/compose-multiplatform/",
            fontSize = 14.sp,
            fontWeight = FontWeight.Medium
        )
    }
}

@Composable
private fun Signature() {
    val year = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year
    RobotoText(
        "${year}, daneshvar.co",
        fontSize = 11.sp,
        fontWeight = FontWeight.Light,
        modifier = Modifier.padding(top = 8.dp)
    )
}

@Composable
fun ProfileImage() {
    Image(
        painter = painterResource(Res.drawable.profile_image),
        contentDescription = null,
        contentScale = ContentScale.Crop,
        modifier = Modifier
            .fillMaxHeight()
            .aspectRatio(0.76f)
            .clip(RoundedCornerShape(16.dp))
    )
}